/**
 * Base styles for html tags
 */

html,
body {
  @apply bg-white text-gray-800;
  @apply dark:bg-gray-900 dark:text-gray-200;
}

h1,
h2,
h3 {
  @apply font-title font-bold;
}

h1 {
  @apply text-4xl pb-3;
}

h2 {
  @apply text-3xl pb-2;
}

h3 {
  @apply text-2xl pb-1;
}

a {
  @apply text-accent underline hover:no-underline;
}

a.heading-anchor {
  @apply ml-2 font-normal no-underline hover:underline;
}

p {
  @apply pb-2;
}

hr {
  @apply py-3 my-2 border-t border-gray-500;
}

ol {
  @apply list-decimal list-inside;
}

ul {
  @apply list-disc;
}

blockquote {
  @apply my-3 pl-2 italic border-solid border-l-2 border-gray-700 dark:border-gray-300;
}

em {
  @apply italic text-gray-700;
  @apply dark:text-gray-200;
}

/**
 * Styles for code highlighting
 */

.highlight pre {
  @apply font-mono p-5 overflow-x-auto;
}

.highlighter-rouge {
  @apply pb-2;
}

p code,
ol code,
ul code {
  @apply font-mono bg-gray-200 text-gray-700 px-1;
  @apply dark:bg-gray-800 dark:text-gray-200;
}

ul.pagination {
  @apply list-none;
}
